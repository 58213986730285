import { useSelector, useDispatch } from "react-redux";

import classes from "./layout.module.scss";
import { useEffect, useMemo } from "react";
import { getUserObject } from "../../src/redux/actions/user";
import * as C from "../../src/utilities/layoutConstants";
import { isAuthenticated } from "../../src/authentication/api";
import CookieConsent from "react-cookie-consent";
import { Button } from "@headlessui/react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useUser } from "../../src/hooks/useUser";
import Link from "next/link";
import { IsMobileView } from "../../src/utilities/helpers";

const NavbarMain = dynamic(
  () => import("../../src/navigation/navbar/NavbarMain")
);
const FooterMain = dynamic(
  () => import("../../src/navigation/footer/FooterMain")
);

const Layout = (props) => {
  const showLayout = useSelector((state) => state.layout.showLayout); // navbar is hidden when Sign up or log in is attempted
  const showNavbar = useSelector((state) => state.layout.showNavbar); // navbar is hidden when Sign up or log in is attempted

  const [loadedSession, token] = useUser();
  const router = useRouter();
  const dispatch = useDispatch();
  const mobileView = IsMobileView();

  const layout = useMemo(() => {
    if (
      !router.pathname.includes("/counselling") &&
      !router.pathname.includes("/counsellors/")
    ) {
      window.localStorage.removeItem("wdPrevSearch");
    }

    // Ensure it only hides the nav on the pms page
    if (
      router.pathname.includes("find-a-practitioner") ||
      router.pathname.includes("student-matching-service")
    ) {
      return "pms";
    }
    return showLayout;
  }, [router, showLayout]);

  // Check auth status
  useEffect(() => {
    if (!loadedSession) {
      return;
    }
    // Need to check if the JWT token is valid, if not, it may have expired

    if (!token) {
      //user is not currently logged in.
      //check local storage to see if they have a valid session
      try {
        let validToken = isAuthenticated();
        if (validToken) {
          //update redux state

          dispatch(getUserObject(validToken));
        }
      } catch (err) {
        console.error("ERROR", err);
      }
    }
  }, [dispatch, token, loadedSession]);

  return (
    <div
      className={showNavbar ? classes.layout : classes.layoutLogoOnly}
      style={{
        width: "100%",
        minHeight: "100vh",
      }}
    >
      <div className="flex flex-1 min-h-screen flex-col">
        <div className="fixed top-0 left-0 right-0 z-[1000]">
          {layout === "mainblock" ? (
            <NavbarMain blockBG="brand.200" blockColor="brand.50" />
          ) : (
            layout !== "pms" && <NavbarMain />
          )}
        </div>
        <div style={{ marginTop: C.NAVBAR_HEIGHT }}>{props.children}</div>
        {layout !== "pms" && <FooterMain />}
      </div>
      {mobileView ? (
        <CookieConsent
          location="top"
          buttonText="Accept"
          cookieName="acceptedOptionalCookies"
          containerClasses="p-4 z-[9999] bg-brand-text text-white mt-[50%] h-[40%] w-[100%] flex flex-col items-center fixed justify-center"
          buttonClasses="bg-brand text-white px-4 py-2 rounded-full hover:bg-white hover:text-brand transition"
          buttonWrapperClasses="flex items-center h-full"
          enableDeclineButton
          declineButtonText="Decline optional"
          declineButtonClasses="bg-transparent hover:bg-red-500/20 transition text-white rounded-full px-4 py-2 mr-2"
          disableStyles
          contentClasses="flex-1"
        >
          <div className="flex items-center flex-1 w-full">
            <div className="flex flex-col">
              We use some essential cookies to make this service work
              <span style={{ fontSize: "10px" }}>
                {" "}
                We’d also like to use analytics cookies so we can understand how
                you use the service and make improvements
              </span>
            </div>
            <Link href="/privacy-policy" prefetch={false}>
              <Button className="mx-2 rounded-full px-4 py-2 text-sm text-white bg-transparent hover:bg-white/20 transition">
                Read more
              </Button>
            </Link>
          </div>
        </CookieConsent>
      ) : (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="acceptedOptionalCookies"
          containerClasses="p-4 z-[9999] bg-brand-text text-white flex items-center fixed left-0 right-0 bottom-0 flex-wrap justify-between"
          buttonClasses="bg-brand text-white px-4 py-2 rounded-full hover:bg-white hover:text-brand transition"
          buttonWrapperClasses="flex items-center h-full"
          enableDeclineButton
          declineButtonText="Decline optional"
          declineButtonClasses="bg-transparent hover:bg-red-500/20 transition text-white rounded-full px-4 py-2 mr-2"
          disableStyles
          contentClasses="flex-1"
        >
          <div className="flex items-center flex-1 w-full">
            <div className="flex flex-col">
              We use some essential cookies to make this service work
              <span style={{ fontSize: "10px" }}>
                {" "}
                We’d also like to use analytics cookies so we can understand how
                you use the service and make improvements
              </span>
            </div>
            <Link href="/privacy-policy" prefetch={false}>
              <Button className="mx-2 rounded-full px-4 py-2 text-sm text-white bg-transparent hover:bg-white/20 transition">
                Read more
              </Button>
            </Link>
          </div>
        </CookieConsent>
      )}
    </div>
  );
};

export default Layout;
